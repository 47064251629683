<template>
  <v-list-item-group
      v-model="group"
      active-class="deep-white--text text--accent-4"
  >

      <v-list-item
          v-for="(item, i) in permittedItems"
          :key="i"
          class="my-3 p-0"
      >
          <v-list-group
              v-if="item.subItems || Array.isArray(item.subItems)"
              :value="true"
              no-action
              prepend-icon=""
              class="sub-item-list"
              sub-group
          >
              <a
                  v-if="item.href != ''"
                  :href="`${item.href}`"
                  class="px-4 d-flex"
              >
                  <v-list-item-icon class="mr-3">
                      <svg-vue :icon="item.icon"></svg-vue>
                  </v-list-item-icon>
                  <v-list-item-content>
                      {{ item.text }}
                  </v-list-item-content>
              </a>
              <router-link v-else :to="`${item.route}`" class="px-4 d-flex">
                  <v-list-item-icon class="mr-3">
                      <svg-vue :icon="item.icon"></svg-vue>
                  </v-list-item-icon>
                  <v-list-item-content>
                      {{ item.text }}
                  </v-list-item-content>
              </router-link>

              <v-list-item
                  v-for="(subItem, i) in item.subItems"
                  :key="i"
                  class="sub-item my-3"
                  link
              >
                  <a
                      v-if="subItem.href"
                      target="_blank"
                      :href="`${subItem.href}`"
                      class="px-4 d-flex"
                  >
                      <v-list-item-content>
                          {{ subItem.text }}
                      </v-list-item-content>
                  </a>
                  <router-link
                      v-else
                      :to="`${subItem.route}`"
                      class="px-4 d-flex"
                  >
                      <v-list-item-content>
                          {{ subItem.text }}
                      </v-list-item-content>
                  </router-link>
              </v-list-item>
          </v-list-group>

          <router-link
              v-else-if="item.redirect_route"
              :to="`/${item.redirect_route}/${item.route}`"
              class="px-4 d-flex"
          >
              <v-list-item-icon class="mr-3">
                  <svg-vue :icon="item.icon"></svg-vue>
              </v-list-item-icon>
              <v-list-item-content>
                  {{ item.text }}
              </v-list-item-content>
          </router-link>
          <router-link
              v-else
              :to="`/${usertype}/${item.route}`"
              class="px-4 d-flex"
          >
              <v-list-item-icon class="mr-3">
                  <svg-vue :icon="item.icon"></svg-vue>
              </v-list-item-icon>
              <v-list-item-content>
                  {{ item.text }}
              </v-list-item-content>
          </router-link>
      </v-list-item>
      <!-- <v-list-item>
          <router-link 
          :to="`/${usertype}/e-library`"
          class="px-4 d-flex">
              <v-list-item-icon class="mr-3">
                  <svg-vue icon="lms"></svg-vue>
              </v-list-item-icon>
              <v-list-item-content> E-Library </v-list-item-content>
          </router-link>
      </v-list-item> -->
      <v-list-item :disabled="loading"  @click="goToLMS()"  class="px-4" v-if="isEligibleForLMS()">
        <!-- <router-link to="/lms/open/" class="px-4 d-flex"> -->
          <!-- <a href="/api/lms/open/" class="px-4 d-flex"> -->
            
            <v-list-item-icon class="mr-3 ">
                <svg-vue icon="lms_home"></svg-vue>
            </v-list-item-icon>
            <v-list-item-content>
                <p :loading="loading" color="primary" class="text-primary primary--text mb-0" style="padding: auto;"> 
                    {{  loading ? 'Loading...':'LMS Home'  }} 
                </p>
            </v-list-item-content>
           
          <!-- </a> -->
          <!-- </router-link> -->
      </v-list-item>

      <v-list-item :disabled="loading_cbt" @click="goToCBT()"  class="px-4">
            <v-list-item-icon class="mr-3">
                <svg-vue icon="lms_home"></svg-vue>
            </v-list-item-icon>
            <v-list-item-content>
                <p class="text-primary primary--text mb-0"> {{  loading_cbt ? 'Loading...':'CBT'  }} </p>
            </v-list-item-content>
      </v-list-item>

      <v-list-item :disabled="loading_apm" @click="goToAPM()"  class="px-4" v-if="isAppraisable()">
            <v-list-item-icon class="mr-3">
                <svg-vue icon="appraisal_home"></svg-vue>
            </v-list-item-icon>
            <v-list-item-content>
                <p class="text-primary primary--text mb-0" style="padding: auto;"> {{  loading_apm ? 'Loading...':'Appraisal Home'  }} </p>
            </v-list-item-content>
      </v-list-item>
    </v-list-item-group>

</template>

<script>
import UserAPI from "../../apis/User";
import AdmissionRoundAPI from "../../apis/AdmissionRound";
import axios from "axios";
import { useUserStore } from "../../stores/userStore";
export default {
  props: ["items", "roles", "permissions", "usertype", "environment"],
  data() {
      return {
          allItems: this.items,
          permittedItems: [],
          group: null,
          myPermissions: this.permissions,
          myRoleNames: this.roles,
          loading:false,
          loading_apm:false,
          loading_cbt: false
      };
  },
  methods: {
      pruneSideBarItemsWithAdmissionRequirementsCheckList() {
          let prunedItems = [];
          UserAPI.auth().then((response) => {
              AdmissionRoundAPI.getAdmissionRoundByID(
                  response.data.data.admission_round_id
              ).then((res) => {
                  let checkList = res.data.data.checkList;
                  //console.log(checkList)
                  this.items.forEach((item) => {
                      //  console.log(item);
                      let check = item.check;
                      if (`check == ""||checkList.${check}`) {
                          prunedItems.push(item);
                      }
                  });
              });
              console.log(prunedItems);
          });
          this.allItems = prunedItems;
      },

      loadRolesAndPermissionNames() {
          // UserAPI.getMyRolesAndPermissions(this.myPermissions)
          //   .then(response=>{
          // this.myRoleNames = response.data.roleNames;
          // this.myPermissions = response.data.permissionNames;
          this.allItems.forEach((item) => {
              if (this.userHasPriviledgeTo(item.permissions)) {
                  this.permittedItems.push(item);
              }
          });

          //   console.log(this.myRoleNames)
          // });
      },

      initialize() {
          //this.pruneSideBarItemsWithAdmissionRequirementsCheckList();
          this.loadRolesAndPermissionNames();
      },

      userHasPriviledgeTo(requiredPermissions) {
          // console.log('user type: '+this.usertype);
          return (
              this.myRoleNames.includes("Super Admin") 
              || ( this.environment==='institute'  && (this.myRoleNames.includes("Director")||this.myRoleNames.includes("Deputy Director")))
              ||  requiredPermissions.length == 0 
              ||this.arraysHaveCommonElements(
                      this.myPermissions,
                      requiredPermissions
                  )
          );
      },

      // check if two arrays have common elements
      arraysHaveCommonElements(arr1, arr2) {
          let commonElements = arr1.filter((element) =>
              arr2.includes(element)
          );
          if (commonElements.length > 0) {
              return true;
          } else {
              return false;
          }
      },

      goToLMS(){
        this.loading = true;
        axios.get('/api/lms/open', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('tertiary_token')}`
            }
        })
        .then(response=>{
                let redirect_url = response.data.redirect_url
                    + '?institution_url='+response.data.institution_url
                    + '&token='+response.data.token;
                    console.log(redirect_url);
                window.location.href=redirect_url;
            }   
        )
       // .finally(() => this.loading = false)
      },

      goToCBT(){
        this.loading_cbt = true;
        axios.get('/api/cbt/open', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('tertiary_token')}`
            }
        })
        .then(response=>{
            console.log(response.data)
                let redirect_url = response.data.redirect_url
                    + '?institution_url='+response.data.institution_url
                    // + '&token='+response.data.token;
                    + '&token='+localStorage.getItem('tertiary_token')
                    console.log(redirect_url);
                window.location.href=redirect_url;
            }   
        )
       .finally(() => this.loading_cbt = false)
      },

      goToAPM(){
        this.loading_apm = true;
        axios.get('/api/apm/open', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('tertiary_token')}`
            }
        })
        .then(response=>{
                let redirect_url = response.data.redirect_url
                    + '?institution_url='+response.data.institution_url
                    + '&token='+response.data.token;
                    console.log(redirect_url);
                window.location.href=redirect_url;
            }   
        )
        //.finally(() => this.loading_apm = false)

      },

      isEligibleForLMS(){
        return this.myRoleNames.includes('Lecturer')||this.myRoleNames.includes('Student')||this.myRoleNames.includes('Super Admin');
      },
      isAppraisable(){
        return this.myRoleNames.includes('Lecturer')||this.myRoleNames.includes('Staff')||this.myRoleNames.includes('Super Admin');
      },
  },
  created() {
      this.initialize();
  },
};
</script>
<style scoped>
.v-overlay {
    z-index: 9999;
    display: grid;
    place-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
#app { /* Ensure the app container has no overflow issues */
  position: relative;
  overflow: hidden;
}
</style>